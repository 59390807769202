import React from 'react'

const Sent = (props) => {



    return (
        <React.Fragment>
            <p className={"success"}>
                <i className={"fa fa-check-circle"}></i>
            </p>
            <h1 className={"title cntr"}>
                Хүсэлт амжилттай илгээгдлээ<br/>
                <span>Та дахин өөр зээл хойшлуулах хүсэлт илгээх бол эхлэл хуудасруу буцаж дахин илгээнэ үү</span>
            </h1>
            <div className={"inner-center"}>
                <button onClick={() => props.page("home")}>Эхлэлрүү буцах</button>
            </div>
        </React.Fragment>    
    )
}

export default Sent