import React, {useState} from 'react'
import './App.css';
import Home from './components/home'
import Form from './components/form'
import Sent from './components/sent'

function App() {

  const [page, setPage] = useState("home")

  return (
    <React.Fragment>
        <div className={"max-width"}>
          <div className="header">
              <img src="images/logo-big.png" className="logo" alt={"Logo"}/>
          </div>
        </div>
        <div className={"max-width"}>
          <div className={"content"}>
              {page === 'home' ? <Home page={setPage}/> : null}
              {page === 'form' ? <Form page={setPage}/> : null}
              {page === 'sent' ? <Sent page={setPage}/> : null}
          </div>
        </div>
    </React.Fragment>
  );
}

export default App;
