import React from 'react'

const Home = (props) => {



    return (
        <React.Fragment>
            <h1 className={"title"}>
                Эрхэм харилцагч таны<br/>
                АМАР АМГАЛАН АЙЛТГАЯ
            </h1>

            <p className={"parag indent"}>
                “Хүлэг капитал” ББСБ нь Монгол Улсын Засгийн Газрын 183 тоот тогтоолыг дагуу Санхүүгийн 
                Зохицуулах Хорооны 3/4654 тоот мэдэгдлийг үндэслэн 
                санхүүгийн хүндрэл учирсан  зээлдэгчдийн зээлийн 
                нөхцөлд өөрчлөлт оруулах хүсэлтйиг ШИМТГЭЛ, 
                ХУРААМЖГҮЙГЭЭР шийдвэрлэж байна. 
                Танд хөл хорионоос шалтгаалж САНХҮҮГИЙН ХҮНДРЭЛ ҮҮССЭН бол 
                зээлийн нөхцөлд өөрчлөлт оруулах хүсэлтээ доорх холбоосоор дарж илгээнэ үү.
            </p>

            <p className={"parag indent"}>
                ББСБ таны хүсэлт гаргасан өдрөөс эхлэн 2021.07.01 хүртэлх үндсэн зээл ба хүүний төлөлтийг 
                хойшлуулах бөгөөд НЭМЭЛТ ӨӨРЧЛӨЛТИЙН ГЭРЭЭ-г хэрхэн баталгаажуулахыг эргэн мэдээлнэ.
            </p>

            <p className={"parag indent"}>
                Зээлийн гэрээнийн нөхцөлд өөрчлөлт оруулах нь харилцагч таны хүсэлтээр зээлийн хугацааг сунгаж, 
                зээлийн үндсэн төлөлтийг 2021.07.01 хүртэлх хугацаагаар хойшлуулах ба харилцагч та энэ хугацаанд 
                зээлийн хүүгийн төлөлтөө сар бүр төлвөл ирээдүйд хүүгийн төлбөр хуримтлагдаж, санхүүгийн ачаалал 
                үүсэхээс сэргийлнэ
            </p>

            <p className={"parag"}>
                Мөн энэ хугацаанд ТОРГУУЛИЙН ХҮҮ, АЛДАНГИ тооцохгүй болохыг мэдэгдэж байна.
            </p>

            <p className={"parag indent"}>
                Эрхэм харилцагч та зээлийн хугацаа сунгах, төлбөрийн хуваарь өөрчлөх хүсэлтээ <span>zeel@khc.mn </span>
                и-мэйл хаягт илгээнэ үү. ББСБ тантай эргэн холбогдох болно. 
            </p>
            <div className={"inner-center"}>
                <button onClick={() => props.page("form")}>Хүсэлт илгээх</button>
            </div>
        </React.Fragment>    
    )
}

export default Home