import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';

const Form = (props) => {

    const { register, handleSubmit, errors } = useForm();
    const [sending, setSending] = useState(false);

    const sendEmail = async (data) =>{
        setSending(true)
        let result = await emailjs.send('service_b3c49qe', 'template_dzgbx5m', data, 'user_xYe3ikn4W0XfMRA5DGEZK')
        setSending(false)
        if(result.status === 200){
            props.page("sent")
        }
    }

    const onSubmit = (data) => {
        sendEmail(data)
        return false;
    };

    const validations = {
        text: /^[А-Яа-я- ӨҮөү]+$/i,
        registerNum: /^([А-Яа-яӨҮөү]{2})?([0-9]{8})$/i,
        phoneNum: /^[0-9]{8}$/i,
        email: /\S+@\S+\.\S+/,
    }

    console.log(props)

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"form"}>
                    <h1>
                        ЗЭЭЛИЙН ГЭРЭЭНИЙ НӨХЦӨЛ ӨӨРЧЛӨХ ХҮСЭЛТ
                    </h1>
                    <h2>
                        Та шаардлагатай мэдээллүүдийг үнэн зөв, бүрэн бөглөнө үү.
                    </h2>
                    <h3>ЕРӨНХИЙ МЭДЭЭЛЭЛ</h3>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Овог <span>*</span></label>
                            <input type="text" name="lastname" ref={register({ required: true, pattern: validations.text })}/>
                            <p className="error">{errors.lastname && <span>Крилл үсгээр бичнэ үү. Жишээлбэл: Бат</span>}</p>
                        </div>
                        <div className={"field"}>
                            <label>Нэр <span>*</span></label>
                            <input type="text" name="firstname" ref={register({ required: true, pattern: validations.text })}/>
                            <p className="error">{errors.firstname && <span>Крилл үсгээр бичнэ үү. Жишээлбэл: Бат</span>}</p>
                        </div>
                    </div>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Регистрийн дугаар <span>*</span></label>
                            <input type="text" name="registerNum" ref={register({ required: true, pattern: validations.registerNum })}/>
                            <p className="error">{errors.registerNum && <span>Регистрийн дугаараа зөв оруулна уу.</span>}</p>
                        </div>
                        <div className={"field"}>
                            <label>Утасны дугаар <span>*</span></label>
                            <input type="number" name="phoneNum" ref={register({ required: true, pattern: validations.phoneNum })}/>
                            <p className="error">{errors.phoneNum && <span>Утасны дугаараа зөв оруулна уу.</span>}</p>
                        </div>
                    </div>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Цахим шуудан <span>*</span></label>
                            <input type="email" name="email" ref={register({ required: true, pattern: validations.email })}/>
                            <p className="error">{errors.email && <span>Цахим шуудангаа зөв оруулна уу.</span>}</p>
                        </div>
                    </div>
                    <div className={"line"}/>
                    <h3>ЗЭЭЛИЙН МЭДЭЭЛЭЛ</h3>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Зээлийн төрөл <span>*</span></label>
                            <select name="loanType" ref={register({ required: true })}>
                                <option value={""}></option>
                                <option value={"үл хөдлөх хөрөнгө барьцаалсан зээл"}>үл хөдлөх хөрөнгө барьцаалсан зээл</option>
                                <option value={"автомашин барьцаалсан зээл"}>автомашин барьцаалсан зээл</option>
                                <option value={"эргэлтийн хөрөнгийн зээл"}>эргэлтийн хөрөнгийн зээл</option>
                                <option value={"цалин орлого барьцаалсан зээл"}>цалин орлого барьцаалсан зээл</option>
                                <option value={"лизинг"}>лизинг</option>
                            </select>
                            <p className="error">{errors.loanType && <span>Зээлийн төрөл сонгоно уу.</span>}</p>
                        </div>
                        <div className={"field"}>
                            <label>Зээлийн гэрээний дугаар <span>*</span></label>
                            <input type="text" name="loanNum" ref={register({ required: true })}/>
                            <p className="error">{errors.loanNum && <span>Зээлийн гэрээний дугаар оруулна уу.</span>}</p>
                        </div>
                    </div>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Зээлийн хугацаа сунгах сар <span>*</span></label>
                            <select name="loanDur" ref={register({ required: true })}>
                                <option value={""}></option>
                                <option value={0}>Хугацаа сунгахгүй</option>
                                <option value={1}>1 сар</option>
                                <option value={2}>2 сар</option>
                                <option value={3}>3 сар</option>
                                <option value={4}>4 сар</option>
                                <option value={5}>5 сар</option>
                                <option value={6}>6 сар</option>
                                <option value={7}>7 сар</option>
                                <option value={8}>8 сар</option>
                                <option value={9}>9 сар</option>
                                <option value={10}>10 сар</option>
                                <option value={11}>11 сар</option>
                                <option value={12}>12 сар</option>
                            </select>
                            <p>*Таны зээл хаагдах хугацааг сунгах сарын тоо</p>
                            <p className="error">{errors.loanDur && <span>Хугацаа сунгах сар сонгоно уу.</span>}</p>
                        </div>
                        <div className={"field"}>
                            <label>Хүүний төлөлтийн хуваарийг хойшлуулах хугацаа <span>*</span></label>
                            <select name="loanRate" ref={register({ required: true })}>
                                <option value={""}></option>
                                <option value={0}>Төлбөр хойшлуулахгүй</option>
                                <option value={1}>1 сар</option>
                                <option value={2}>2 сар</option>
                                <option value={3}>3 сар</option>
                                <option value={4}>4 сар</option>
                                <option value={5}>5 сар</option>
                                <option value={6}>6 сар</option>
                                <option value={7}>7 сар</option>
                                <option value={8}>8 сар</option>
                                <option value={9}>9 сар</option>
                                <option value={10}>10 сар</option>
                                <option value={11}>11 сар</option>
                                <option value={12}>12 сар</option>
                            </select>
                            <p>*Таны зээлийн хүүний эргэн төлөлтийг хойшлуулах сарын тоо</p>
                            <p className="error">{errors.loanRate && <span>Хугацаа сунгах сар сонгоно уу.</span>}</p>
                        </div>
                    </div>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <label>Үндсэн төлөлтийн хуваарийг хойшлуулах хугацаа <span>*</span></label>
                            <select name="loanBase" ref={register({ required: true })}>
                                <option value={""}></option>
                                <option value={0}>Төлбөр хойшлуулахгүй</option>
                                <option value={1}>1 сар</option>
                                <option value={2}>2 сар</option>
                                <option value={3}>3 сар</option>
                                <option value={4}>4 сар</option>
                                <option value={5}>5 сар</option>
                                <option value={6}>6 сар</option>
                                <option value={7}>7 сар</option>
                                <option value={8}>8 сар</option>
                                <option value={9}>9 сар</option>
                                <option value={10}>10 сар</option>
                                <option value={11}>11 сар</option>
                                <option value={12}>12 сар</option>
                            </select>
                            <p className="error">{errors.loanBase && <span>Хугацаа сунгах сар сонгоно уу.</span>}</p>
                        </div>
                    </div>
                    <p className={"danger"}>
                        Хүлэг Капитал ББСБ - аас авсан зээлийн эргэн төлөлтөд COVID-19 вирусээс шалтгаалан бэрхшээл учирсан тул зээлийн гэрээний 
                        нөхцөлд өөрчлөлт оруулах хүсэлтийг минь шийдвэрлэж өгнө үү.
                    </p>
                    <div className={"fields"}>
                        <div className={"field"}>
                            <input type="checkbox" name="agreement" className="checkbox" ref={register({ required: true })}/>
                            <span className={"checkbox"}>Зөвшөөрч байна</span>
                            <p className="error">{errors.agreement && <span>Та зөвшөөрч байна уу.</span>}</p>
                        </div>
                    </div>
                    <div className={"inner-center"}>
                        <button disabled={sending}>
                            <i className={sending ? "fa fa-refresh fa-spin" : ""}></i>Хүсэлт баталгаажуулах
                        </button>
                    </div>
                </div>
            </form>
        </React.Fragment>    
    )
}

export default Form